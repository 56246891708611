import React from 'react'

const ProjectItem = (props) => {


    return (
        <div className='projectItem'>
            <img src={props.backgroundURL} className='projectItem__img' alt={props.nombre}></img>
            <div className='projectItem_top'>
                <h2 className='projectItem_titulo'>{props.nombre}</h2>
                <div className='projectItem_iconos' style={{ display: 'flex', gap: '1rem' }}>
                    <a href={`${props.link}`}><i className="projectItem_icono ri-global-line "></i></a>
                    <a href={`${props.github}`}><i className="projectItem_icono ri-github-fill "></i></a>
                </div>
            </div>
            <h2 className='projectItem_tecs'>{props.tecs}</h2>
            <p className='projectItem_descripcion'>{props.descripcion}</p>

        </div >


    )


}

export default ProjectItem