import React from 'react'

function ResponsiveNav() {


    return (
        <div className='responsiveNav_container'>
            <img src="./assets/logo_portfolio_s.png" alt="logo s" className='responsiveNav_logo' />
            <i className="responsiveNav_icono ri-menu-line" onClick={() => document.querySelector('.menu__responsive').classList.toggle('active')}></i>
            <span className="menu__responsive">
                <ul className="menu__responsive-ul">
                    <i className="responsiveNav_icono-cerrar ri-close-line" onClick={() => document.querySelector('.menu__responsive').classList.toggle('active')}></i>
                    <li><a href='/' className="menu__responsive-links">HOME</a></li>
                    <hr className='menu__responsive-hr' />
                    <li><a href='/projects' className="menu__responsive-links">PROJECTS</a></li>
                    <hr className='menu__responsive-hr' />
                    <li><a href='/setup' className="menu__responsive-links">SETUP</a></li>
                </ul>
            </span>
        </div>
    )
}

export default ResponsiveNav






