import React from "react";
import Setup from "../components/Setup";

const SetupPage = () => {

    return (
        <div>
            <Setup />
        </div>
    )
}

export default SetupPage