import React from "react"
import { motion } from 'framer-motion'
import Itemcard from "./Itemcard"

const Setup = () => {

    return (
        <div className="setup__container">
            <motion.img src="/assets/setup.webp" className="setup__img" />

            <div className="setup__cardsContainer">
                <Itemcard
                    nombre='Logitech MX Masters 3S'
                    backgroundURL='/assets/fotos-setup/mouse.jpg'
                    link='https://www.amazon.es/Logitech-Master-Inal%C3%A1mbrico-Performance-Desplazamiento/dp/B07W5JKHFZ/ref=sr_1_3?crid=LHBRKZXTGK0T&keywords=mx+master+3&qid=1700247278&sprefix=mx%2Caps%2C301&sr=8-3&ufe=app_do%3Aamzn1.fos.7911a93e-3532-4810-a632-e84fe123af2b'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard nombre='Keychron K2 V2 Gateron G Pro'
                    backgroundURL='/assets/fotos-setup/keyboard.jpg'
                    link='https://es.aliexpress.com/item/4001366296876.html'
                    icono='https://ae01.alicdn.com/kf/S3d28d57a0ea7496591f20866e532e1c1F.png_640x640.png_.webp' />

                <Itemcard nombre='GAME M27E2-4K 27" VA - UHD 4K - 60HZ'
                    backgroundURL='/assets/fotos-setup/monitor1.jpg'
                    link='https://www.game.es/game-m27e2-4k-27-va-uhd-4k-60hz-con-altavoces-monitor-gaming-pc-hardware-202632'
                    icono='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEWnAISxHpK+RKXNcLrcms7nud3hqNXTgMHGXLC1KZetEozRer7syuX79Pr////15PLLaLby2ez+/P3+/v736fS5Np7IYbP47fb9+fzXjMfdntDpwOHrxePDUqy7O6Hv0ejZkcryCoy0AAAA50lEQVR4AdSSBZLEIBAAieNHkLj9/5OxOZvIA7bLkB4rIB9HFCdpluUFZfzpmgupNPCVmeh2T0vr9C/WB4Kp6uPcWWvBa2J8b87sddaaquh6fxOYP4KyGJrjoShL1ATv3H7f8X8neI5wJBjwGSI5CozknXwXJBSdDFChfMM+YA4nqQZs8V+Yd2EhSID9Q4bF3gXoYYYeWJumqb8K4piC4oxImGpI8Srw3O19Z+xVIKHROwPl4MubQMxRxDU+K8SSeXsXeNI4/Q8kAOOABJ/cv2wivWqstY3ys5ge3zbQRIjEjGzb4MptALrTE5eDbnNqAAAAAElFTkSuQmCC' />

                <Itemcard
                    nombre='Samsung UE22H5000AW'
                    backgroundURL='/assets/fotos-setup/monitor2.jpg'
                    link='https://www.amazon.es/Samsung-UE22H5000AW-Ue22H5000-Full-Hdmi/dp/B00KIUO0E4/ref=cm_cr_arp_d_product_top?ie=UTF8'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Flexispot EF1 + custom board'
                    backgroundURL='/assets/fotos-setup/desktop.jpg'
                    link='https://www.amazon.es/FLEXISPOT-Escritorio-Ajustable-autom%C3%A1tica-Inteligente/dp/B07HFZLYNH?ref_=ast_sto_dp&th=1&psc=1'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='ASUS VivoBook F1500EA'
                    backgroundURL='/assets/fotos-setup/laptop.jpg'
                    link='https://www.worten.es/productos/portatil-asus-vivobook-f1500ea-bq3075-15-6-intel-core-i5-1135g7-ram-16-gb-512-gb-ssd-intel-iris-xe-graphics-7682033'
                    icono='https://play-lh.googleusercontent.com/8_XBPWRyDYWFVyVBGJYKU_5d5hxZGCYeh4SUhGpcRCaz_PE8GMSA70I9wF-qA6DJxGg' />

                <Itemcard
                    nombre='UGREEN Charging Station'
                    backgroundURL='/assets/fotos-setup/charging.jpg'
                    link='https://www.amazon.es/UGREEN-Cargador-Inal%C3%A1mbrico-Estacion-Magnetico/dp/B0B9X1RY8G/ref=sr_1_3?__mk_es_ES=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3CYFI1YK5169Y&keywords=ugreen+20w+dock&qid=1706098935&sprefix=ugreen+20w+dock%2Caps%2C137&sr=8-3'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Nintendo Switch'
                    backgroundURL='/assets/fotos-setup/switch.jpg'
                    link='https://www.amazon.es/Consola-Nintendo-Switch-Color-Azul/dp/B0BHDDH5W1/ref=sr_1_6?__mk_es_ES=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=nintendo+switch&qid=1706048448&sr=8-6'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Nintendo Switch Pro Controller'
                    backgroundURL='/assets/fotos-setup/procontroller.jpg'
                    link='https://www.amazon.es/Nintendo-Switch-Mando-Controller-Cable/dp/B01N4ND1T2/ref=sr_1_5?__mk_es_ES=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2WOILUNUC55LQ&keywords=nintendo+switch+pro+controller&qid=1706048495&sprefix=nintendo+switch+pro+controlle%2Caps%2C126&sr=8-5'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Downtress Desktop Mat'
                    backgroundURL='/assets/fotos-setup/mat.jpg'
                    link='https://www.amazon.es/Alfombrilla-Fieltro-Antideslizante-Almohadilla-Teclado/dp/B096S2Z9Q7/ref=sr_1_1?__mk_es_ES=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=F6ZY4M8RM2QB&keywords=downtress+mat+desktop&qid=1706100161&sprefix=downtress+mat+desktop%2Caps%2C105&sr=8-1'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Sony PS4'
                    backgroundURL='/assets/fotos-setup/ps4.jpg'
                    link='https://www.amazon.es/PlayStation-Slim-PS4-Consola-500/dp/B01LQF9RDI/ref=sr_1_2?__mk_es_ES=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1XVJVXM521VBK&keywords=ps4+consola&qid=1706100249&sprefix=ps4+consola%2Caps%2C107&sr=8-2'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='HEANTTV Bluetooth 5.0 Speaker'
                    backgroundURL='/assets/fotos-setup/speaker.jpg'
                    link='https://www.amazon.es/Altavoces-computadora-HEANTTV-escritorio-alimentado/dp/B08L3H34ZJ/ref=rvi_d_sccl_11/258-2264414-0254467?pd_rd_w=cb3GD&content-id=amzn1.sym.ae62afe1-344b-4cc5-9de9-3f3a769592f2&pf_rd_p=ae62afe1-344b-4cc5-9de9-3f3a769592f2&pf_rd_r=WKCAA4P84P9RWMTW5ADT&pd_rd_wg=IHDRB&pd_rd_r=2d80002d-33d7-4038-adb0-8b09ff207b4b&pd_rd_i=B08L3H34ZJ&psc=1'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

                <Itemcard
                    nombre='Amazon Fire TV'
                    backgroundURL='/assets/fotos-setup/fire.jpg'
                    link='https://www.amazon.es/Altavoces-computadora-HEANTTV-escritorio-alimentado/dp/B08L3H34ZJ/ref=rvi_d_sccl_11/258-2264414-0254467?pd_rd_w=cb3GD&content-id=amzn1.sym.ae62afe1-344b-4cc5-9de9-3f3a769592f2&pf_rd_p=ae62afe1-344b-4cc5-9de9-3f3a769592f2&pf_rd_r=WKCAA4P84P9RWMTW5ADT&pd_rd_wg=IHDRB&pd_rd_r=2d80002d-33d7-4038-adb0-8b09ff207b4b&pd_rd_i=B08L3H34ZJ&psc=1'
                    icono='https://icones.pro/wp-content/uploads/2021/08/logo-amazon-orange.png' />

            </div>
        </div >
    )
}

export default Setup