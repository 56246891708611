import React from "react"
import { useInView } from 'react-intersection-observer';
import Tecs from './Tecs'
import TecsResponsive from './TecsResponsive'


const About = () => {

    const { ref: containerRef } = useInView({ triggerOnce: true })

    return (
        <div className="about__container animated" ref={containerRef}>
            <div className="about__col">
                <figure class="about__profile">
                    <img src="./assets/profile.png" alt="profile" />
                </figure>
            </div>
            <div className="about__col">
                <Tecs />
                <div className="about__paramContainer">
                    <p className='about__param'>
                        <i>I'm a self-tought developer that loves to make pixels become art. I try to give the best proposal in functionality, design and efficiency.</i>
                    </p>
                    <br />
                    <p className='about__param'>
                        <i>If I'm not coding, I'll be getting a </i>☕<i>or watching some videos on YT. Also, do you know I am an official Pokemon Master?</i>
                    </p>
                </div>
            </div>
            <TecsResponsive />
            <div className="about__paramContainer_responsive">
                <p className='about__param_responsive'>
                    <i>I'm a self-tought developer that loves to make pixels become art. I try to give the best proposal in functionality, design and efficiency.</i>
                </p>
                <br />
                <p className='about__param_responsive'>
                    <i>If I'm not coding, I'll be getting a </i>☕<i>or watching some videos on YT. Also, do you know I am an official Pokemon Master?</i>
                </p>
            </div>
        </div>
    )
}

export default About