import React, { useEffect } from "react"
import { motion } from 'framer-motion'


const Home = () => {

    const animacionIconos = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    }

    useEffect(() => {
    }, []);

    const animacionTituloVariables = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    }

    useEffect(() => {
    }, []);

    const enviarEmail = () => {
        const direccion = 'ger@gerespinosa.com';
        const asunto = 'Me gustaría ponerme en contacto contigo';
        const cuerpo = '';

        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(direccion)}&su=${encodeURIComponent(asunto)}&body=${encodeURIComponent(cuerpo)}`;


        window.open(mailtoLink, '_blank');
    }

    return (
        <div className="home__container"
            id="home">
            <div className="home__tituloContainer">
                <p className="home__texto_pre">HELLO. I AM</p>

                <motion.h1
                    className="home__titulo"
                    initial='hidden'
                    animate='visible'
                    variants={animacionTituloVariables}
                    transition={{ duration: 1 }}

                >
                    GER ESPINOSA
                </motion.h1>

                <p className="home__texto_post">
                    SPANISH <span className="home__container_span">DEVELOPER</span> BASED ON SEVILLE
                </p>
            </div>

            <div className="home__social_responsive_container">
                <motion.a href="https://www.github.com/gerespinosa" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i
                        initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-github-fill"></motion.i>
                </motion.a>

                <motion.a href="https://www.linkedin.com/in/gerespinosa/" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i
                        initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-linkedin-fill"></motion.i>

                </motion.a>
                <motion.a href="https://www.twitter.com/gerespinosa_" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i whileHover={{
                        color: 'var(--extra)',
                        cursor: 'pointer'
                    }} initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-twitter-x-fill"></motion.i>
                </motion.a>

                <motion.a href="https://www.instagram.com/gerespinosa_" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i
                        initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-instagram-fill"></motion.i>

                </motion.a>
                <motion.i onClick={enviarEmail}
                    initial='hidden'
                    animate='visible'
                    // variants={animacionIconos}
                    transition={{ duration: 1 }}
                    className="social__icon ri-mail-send-line"></motion.i>
                <span className="social__line_responsive"></span>
            </div>
        </div>
    );


}

export default Home
