import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import './App.css'
import HomePage from "./pages/HomePage";
import SetupPage from "./pages/SetupPage";
import ProjectsPage from "./pages/ProjectsPage";

import Navbar from "./components/Navbar";
import Social from "./components/Social";
import ResponsiveNav from "./components/ResponsiveNav";

const App = () => {

    return (
        <BrowserRouter>
            <header>
                <Navbar />
                <ResponsiveNav />
            </header>
            <div className="body">
                <Social />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/setup" element={<SetupPage />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App