import React, { useEffect } from "react";
import { motion } from 'framer-motion'


const Social = () => {

    const enviarEmail = () => {
        const direccion = 'ger@espinosatoscano.com';
        const asunto = 'Me gustaría ponerme en contacto contigo';
        const cuerpo = '';

        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(direccion)}&su=${encodeURIComponent(asunto)}&body=${encodeURIComponent(cuerpo)}`;


        window.open(mailtoLink, '_blank');
    }

    const animacionIconos = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    }

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="social__container">
                <motion.a href="https://www.github.com/gerespinosa" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i
                        initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-github-fill"></motion.i>
                </motion.a>

                <motion.a href="https://www.linkedin.com/in/gerespinosa/" rel="noopener noreferrer" target="_blank" whileHover={{
                    scale: '1.1'
                }}>
                    <motion.i
                        initial='hidden'
                        animate='visible'
                        variants={animacionIconos}
                        transition={{ duration: 1 }}
                        className="social__icon ri-linkedin-fill"></motion.i>

                </motion.a>
                <span className="social__line"></span>
            </div>

            <div className="social__containerMail">
                <motion.p className="social__containerMail-texto"
                    initial='hidden'
                    animate='visible'
                    variants={animacionIconos}
                    transition={{ duration: 1 }}
                    onClick={enviarEmail}>ger@gerespinosa.com
                </motion.p>
                <span className="social__line"></span>
            </div>

        </div>
    )
}

export default Social