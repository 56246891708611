import React from "react";
import { motion } from 'framer-motion';

const ItemCard = (props) => {

    return (
        <motion.div className="itemcard" style={{ backgroundImage: `url('${props.backgroundURL}')` }}>
            <motion.div className="itemcard__overlay"   >
                <h2 className="itemcard__texto">{props.nombre}</h2>
                <a href={`${props.link}`}>
                    <img src={`${props.icono}`} alt={props.nombre} className="itemcard__icon" />
                </a>
            </motion.div>
        </motion.div>
    );
};

export default ItemCard;
