import React from "react"
import { motion } from 'framer-motion'


const Navbar = () => {

    const enviarEmail = () => {
        const direccion = 'ger@espinosatoscano.com';
        const asunto = 'Me gustaría ponerme en contacto contigo';
        const cuerpo = '';

        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(direccion)}&su=${encodeURIComponent(asunto)}&body=${encodeURIComponent(cuerpo)}`;

        window.open(mailtoLink, '_blank');
    }


    return (
        <div className="nav__container" >
            <div className="nav__logo">
                <img src={"../assets/logo_portfolio.png"} alt="logo" className="nav__logo_img" />
            </div>
            <div>
                <ul className="nav__menu">
                    <li>
                        <motion.a
                            href="/"
                            className="nav__links"
                        >HOME
                        </motion.a>
                    </li>

                    <li>
                        <motion.a
                            href="/projects"
                            className="nav__links"
                        >PROJECTS
                        </motion.a>
                    </li>

                    <li>
                        <motion.a
                            href="/setup"
                            className="nav__links"
                        >SETUP
                        </motion.a>
                    </li>
                </ul>
            </div>
            <div>
                <motion.button className="nav__btn"

                    onClick={enviarEmail}
                >
                    LET'S TALK! <i class="ri-arrow-right-up-line"></i>
                </motion.button>
            </div>
        </div >
    )
}

export default Navbar