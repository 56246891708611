import React from 'react'
import { motion } from 'framer-motion'

const ProjectCard = (props) => {

   
        return (
            <motion.div className="projectCard" style={{ backgroundImage: `url('${props.backgroundURL}')` }}
            >
                <motion.div className='projectCard_overlay'>
                    <h2 className='projectCard_titulo'>{props.nombre}</h2>
                    <div className='projectCard_iconos' style={{ display: 'flex', gap: '1rem' }}>
                        <a href={`${props.link}`}><i className="projectCard_icono ri-global-line "></i></a>
                        <a href={`${props.github}`}><i className="projectCard_icono ri-github-fill "></i></a>
                    </div>
                    <h2 className='projectCard_tecs'>{props.tecs}</h2>
                    <p className='projectCard_descripcion'>{props.descripcion}</p>
                </motion.div>
            </motion.div >
        )
        
    
}

export default ProjectCard