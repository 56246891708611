import React from "react";
import Home from "../components/Home";
import About from "../components/About";

const HomePage = () => {
    return (
        <div>
            <Home />
            <About />
        </div>
    )
}

export default HomePage