import React from 'react'
import ProjectCard from './ProjectCard'
import ProjectItem from './ProjectItem'

const Projects = () => {

    return (
        <div className='projects__container'>
            <div className="projects__cardsContainer">
                <ProjectCard
                    nombre="Apple Copycat"
                    backgroundURL="./assets/proyectos/appleclone.png"
                    link="https://apple-website-clone-by-ger-espinosa.netlify.app/"
                    github='https://github.com/gerespinosa/apple_website_clone'
                    tecs={[
                        <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white" alt="html5" />,
                        <img src="https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white" alt="css3" />,
                        <img src="https://img.shields.io/badge/JavaScript-323330?style=for-the-badge&logo=javascript&logoColor=F7DF1E" alt="javascript" />
                    ]}
                    descripcion='Apple website copycat'
                />
                <ProjectCard
                    nombre="Nuevo Estilo"
                    backgroundURL="./assets/proyectos/nuevoestilo.png"
                    link="https://nuevoestilo.onrender.com/"

                    tecs={[
                        <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white" alt="html5" />,
                        <img src="https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white" alt="tailwindCSS" />,
                        <img src="https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB" alt="react" />,
                        <img src="https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white" alt="mongodb" />,
                        <img src="https://img.shields.io/badge/Express%20js-000000?style=for-the-badge&logo=express&logoColor=white" alt="express" />
                    ]}
                    descripcion='MERN Proyect for hair style saloon guest management'
                />
            </div>

            <div className='projects__itemContainer'>
                <ProjectItem
                    nombre="Nuevo Estilo"
                    backgroundURL="./assets/proyectos/nuevoestilo.png"
                    link="https://nuevoestilo.onrender.com/"

                    tecs={[
                        <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white" alt="html5" />,
                        <img src="https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white" alt="tailwindCSS" />,
                        <img src="https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB" alt="react" />,
                        <img src="https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white" alt="mongodb" />,
                        <img src="https://img.shields.io/badge/Express%20js-000000?style=for-the-badge&logo=express&logoColor=white" alt="express" />
                    ]}
                    descripcion='MERN Proyect for hair style saloon guest management'
                />
                <ProjectItem
                    nombre="Apple Copycat"
                    backgroundURL="./assets/proyectos/appleclone.png"
                    link="https://apple-website-clone-by-ger-espinosa.netlify.app/"
                    github='https://github.com/gerespinosa/apple_website_clone'
                    tecs={[
                        <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white" alt="html5" />,
                        <img src="https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white" alt="css3" />,
                        <img src="https://img.shields.io/badge/JavaScript-323330?style=for-the-badge&logo=javascript&logoColor=F7DF1E" alt="javascript" />
                    ]}
                    descripcion='Apple website copycat'
                />
            </div>
        </div>
    )
}

export default Projects